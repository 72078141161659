import React from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Stack,
  Heading,
  useBreakpointValue,
  Image,
  Flex,
} from "@chakra-ui/react";
import Zoom from "react-medium-image-zoom";

const OurOnlinegames = ({ shopsRef }) => {
  const columns = useBreakpointValue({ base: 1, md: 2 });
  return (
    <Stack flex={1} spacing={{ base: 5, md: 10 }} minWidth="60%" ref={shopsRef}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
        mt="2rem"
      >
        <Text
          as={"span"}
          position={"relative"}
          fontSize="2rem"
          fontFamily="Rockwell Nova"
          //   _after={{
          //     content: "''",
          //     width: "full",
          //     height: "30%",
          //     position: "absolute",
          //     bottom: 1,
          //     left: 0,
          //     bg: "#7d14ff",
          //     zIndex: -1,
          //   }}
          onClick={() => window.open("https://buffalo.ug/", "_blank")?.focus()}
          cursor="pointer"
        >
          OUR ONLINE SLOTS
        </Text>
        <br />
        {/* <Text as={"span"} color={"red.400"}>
          use everywhere!
        </Text> */}
      </Heading>

      <Image
        alt={"Machine Image"}
        objectFit="cover"
        objectPosition="center"
        height="100%"
        width="auto"
        rounded="5px"
        src="/images/11.jpg"
        onClick={() => window.open("https://buffalo.ug/", "_blank")?.focus()}
        cursor="pointer"
      />

      <Box>
        <Text color={"gray.500"} mb="0.4rem">
          Welcome to Buffalo Online Slots. Your one-stop for all slot games,
          where winning is the new normal! Our online platform offers a variety
          of games that will keep you at the edge of your seat, spin after spin!
        </Text>

        <Text color={"gray.500"}>
          Most of all, you get to play them in the comfort of your home, office,
          taxi, or at the boda boda stage!
        </Text>
      </Box>
    </Stack>
  );
};

export default OurOnlinegames;
